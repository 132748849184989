import React from 'react';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Toolbar from '@material-ui/core/Toolbar';
// import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
// import MenuIcon from '@material-ui/icons/Menu';
// import { Link } from 'react-router-dom';
// import clsx from 'clsx';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { fontColor } from '../Utils/constants';
import { makeStyles } from '@material-ui/core/styles';
import Search from '../Search/Search';
// import Bubbles from '../Search/Bubbles';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    backgroundImage: `url('/android-chrome-192x192.png')`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundSize: theme.spacing(4),
  },
  menuButton: {
    marginRight: theme.spacing(0),
    color: fontColor,
  },
  searchButton: {
    color: fontColor,
  },
  hide: {
    display: 'none',
  },
  search: {
    flexGrow: 1,
    maxWidth: '600px',
  },
  flex: {
    flexGrow: 1,
  },
}));

const SearchToolbar = ({
  width,
  openSearch,
  setOpenSearch,
  toggleDrawer,
  open,
}) => {
  const classes = useStyles();

  return (
    <Toolbar>
      {/* //   {width !== 'xs' && (
    //     <IconButton
    //       aria-label='open drawer'
    //       onClick={toggleDrawer(true)}
    //       edge='start'
    //       className={clsx(classes.menuButton, open && classes.hide)}
    //     >
    //       <MenuIcon />
    //     </IconButton>
    //   )} */}
      <ClickAwayListener
        onClickAway={() => {
          setOpenSearch(false);
        }}
      >
        <div style={{ display: 'contents' }}>
          <IconButton
            onClick={() => {
              setOpenSearch(!openSearch);
            }}
          >
            <ArrowBackIcon className={classes.searchButton} />
          </IconButton>
          <div className={classes.search}>
            <Search
              onClick={() => setOpenSearch(false)}
              closeSearch={() => setOpenSearch(false)}
            />
          </div>
          {/* <Bubbles /> */}
        </div>
      </ClickAwayListener>
      {/* {width !== 'xs' && (
        <>
          <div className={classes.flex} />
          <Button component={Link} to={{ pathname: `/donate` }}>
            Donate
          </Button>
        </>
      )} */}
    </Toolbar>
  );
};

export default SearchToolbar;
