import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

import TopCollectionTabs from './TopCollectionTabs';
import { db } from '../../firebase';
import Progress from '../../Utils/Progress';
import { backgroundColor, fontColor } from '../../Utils/constants';

import { withRouter } from 'react-router-dom';

async function fetchCollections() {
  //console.log('fetch collections structure');
  const docRef = await db.doc('songCollections/collectionsStructure');
  return docRef
    .get()
    .then(async function (doc) {
      if (doc.exists) {
        return await doc.data();
      } else {
        // doc.data() will be undefined in this case
        console.log('No such document!');
      }
    })
    .catch(function (error) {
      console.log('Error getting document:', error);
      return error;
    });
}

const songCollections = fetchCollections();

const styles = {
  topTab: {
    backgroundColor: backgroundColor,
    marginTop: '0',
    width: '100%',
    color: fontColor,
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.12), 0 0 4px 0 rgba(0, 0, 0, 0.12)',
  },
  tabContainer: {
    width: '100%',
  },
  progressRoot: {
    textAlign: 'center',
    padding: '20%',
  },
  progress: {
    display: 'inline-block',
    margin: 'auto',
    width: '100%',
    height: '100%',
  },
};

class CollectionTabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mekhor: {},
      message: {},
      barsam: {},
      other: {},
    };
  }

  componentDidMount() {
    songCollections.then((data) => {
      return this.setState({
        mekhor: data['M'],
        message: data['T'],
        barsam: data['B'],
        other: data['O'],
        // children: data['C'],
      });
    });
  }

  render() {
    const { mekhor, message, barsam, other } = this.state;
    const tab = this.props.location.pathname.split('/')[2];

    if (
      mekhor === undefined ||
      mekhor.collections === undefined ||
      mekhor === null ||
      message === null ||
      barsam === null ||
      other === null ||
      message === undefined ||
      barsam === undefined ||
      other === undefined
    ) {
      return <Progress />;
    } else {
      return (
        <TopCollectionTabs
          tab={tab}
          mekhor={mekhor}
          message={message}
          barsam={barsam}
          other={other}
        />
      );
    }
  }
}
export default withRouter(withStyles(styles)(CollectionTabs));
