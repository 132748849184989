import React from 'react';
import { DragDropContext } from 'react-beautiful-dnd';

import SingleLineCollection from '../KhaitaCollections/SongList/SingleLineCollection';

export default function SearchResult (props) {

    const onDragEnd = result => {
        //const { destination, source } = result;     
    }

    if (props.songList === null || props.songList === undefined) {
        return null
    }
    const songListArray = [];
    const songListArrayAll= [];
    const navigation = [];
    let shortList = [];
    let i = 1;
    let j = 1;

    const keys = Object.keys(props.songList);
    keys.sort();

    for (let key of keys) {        
        songListArrayAll.push(props.songList[key]);
        navigation.push(props.songList[key].url);
        shortList.push({
            ...props.songList[key],
            id: i
        });
        j++;
        if (j === 6) {
            songListArray.push(shortList);
            shortList = [];
            j = 1;
        }
        i++;
    }
    if (shortList.length > 0) {
        songListArray.push(shortList);
    }

    return (
        <DragDropContext
                onDragEnd={onDragEnd}
            >
            {songListArray.map((list, index) =>                     
                <SingleLineCollection 
                    key={index}
                    songListArray={list} 
                    navigation={navigation} 
                    linkBack={props.linkBack}
                    songsNumColl={{}}
                    titleCollection={''}
                    songCollectionId={'Search results'}
                    lastLocationState={props.lastLocationState}
                />              
            )}
        </DragDropContext>
    )
}