import React, { useState, useEffect, useRef } from 'react';
import {
  Paper,
  Toolbar,
  // Badge,
  FormGroup,
  FormControlLabel,
  Checkbox,
  IconButton,
} from '@material-ui/core';

//import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import withWidth from '@material-ui/core/withWidth';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import Slide from '@material-ui/core/Slide';
import DialogContent from '@material-ui/core/DialogContent';

import { useLocation } from 'react-router-dom';

import { backgroundColor, fontColor } from '../Utils/constants';
import Glossary from '../Glossary/Glossary';

const useStyles = makeStyles((theme) => ({
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
    textAlign: 'left',
  },
  lyricsPaperScroll: {
    height: '100vh',
    overflowY: 'scroll',
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    maxWidth: '100vw',
  },
  lyricsPaperXS: {
    padding: theme.spacing(0),
    maxWidth: '100vw',
  },
  appBar: {
    position: 'relative',
    //boxShadow: 'none',
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.12), 0 0 4px 0 rgba(0, 0, 0, 0.12)',
    paddingRight: '0 !important',
  },
  toolbar: {
    backgroundColor: backgroundColor,
    color: fontColor,
  },
  toolbarLyrics: {
    padding: theme.spacing(0),
  },
  container: {
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0),
    marginBottom: theme.spacing(6),
  },
  /*lyricsPaper: {
        maxHeight: '95vh',
        overflowY: 'scroll',
        padding: theme.spacing(1),
    },*/
  lyricsText: {
    //overflowX: 'scroll',
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0),
  },
  grow: {
    flexGrow: 1,
  },
  fullScreenHtmlContainer: {
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

function changeNbspToSpace(line, keyVar) {
  let line1 = line.trim();
  let prefix = '';
  if (keyVar === 'Tib' || keyVar === 'Dra') {
    return line;
  } else {
    while (line1.search('&nbsp;') === 0) {
      line1 = line1.replace('&nbsp;', '').trim();
      prefix = prefix + '&nbsp;';
    }
    return prefix + line1.replace(/&nbsp;/g, ' ');
  }
}

function createLyricsHtml(lyrics, checked, width, scale) {
  //const { width } = props;

  /*const tibP = (width === 'xs') ? '1.0em' : '1.5em';
    const tibS = (width === 'xs') ? '0.6em' : '0.8em';

    const wesP = (width === 'xs') ? '1.0em' : '1.5em';
    const wesS = (width === 'xs') ? '0.6em' : '0.8em';*/
  let tibP;
  let tibS;
  let wesP;
  let wesS;
  let gap;
  if (scale === 1) {
    tibP = width === 'xs' ? '18px' : '22px';
    tibS = width === 'xs' ? '12px' : '18px';
    // tibP = (width === 'xs') ? '23px' : '28px';
    // tibS = (width === 'xs') ? '15px' : '23px';

    wesP = width === 'xs' ? '18px' : '22px';
    wesS = width === 'xs' ? '12px' : '18px';

    gap = width === 'xs' ? '46px' : '56px';
  } else if (scale === 2) {
    tibP = width === 'xs' ? '21px' : '29px';
    tibS = width === 'xs' ? '14px' : '20px';
    // tibP = (width === 'xs') ? '27px' : '37px';
    // tibS = (width === 'xs') ? '18px' : '26px';

    wesP = width === 'xs' ? '21px' : '29px';
    wesS = width === 'xs' ? '14px' : '20px';

    gap = width === 'xs' ? '54px' : '74px';
  } else if (scale === 3) {
    tibP = width === 'xs' ? '24px' : '36px';
    tibS = width === 'xs' ? '16px' : '24px';
    // tibP = (width === 'xs') ? '31px' : '46px';
    // tibS = (width === 'xs') ? '20px' : '31px';

    wesP = width === 'xs' ? '24px' : '36px';
    wesS = width === 'xs' ? '16px' : '24px';

    gap = width === 'xs' ? '62px' : '92px';
  } else if (scale === 4) {
    tibP = width === 'xs' ? '30px' : '40px';
    tibS = width === 'xs' ? '22px' : '28px';
    // tibP = (width === 'xs') ? '39px' : '52px';
    // tibS = (width === 'xs') ? '28px' : '36px';

    wesP = width === 'xs' ? '30px' : '40px';
    wesS = width === 'xs' ? '22px' : '28px';

    gap = width === 'xs' ? '78px' : '104px';
  } else if (scale === 5) {
    tibP = width === 'xs' ? '36px' : '44px';
    tibS = width === 'xs' ? '26px' : '34px';
    // tibP = (width === 'xs') ? '46px' : '57px';
    // tibS = (width === 'xs') ? '33px' : '44px';

    wesP = width === 'xs' ? '36px' : '44px';
    wesS = width === 'xs' ? '26px' : '34px';

    gap = width === 'xs' ? '92px' : '114px';
  } else if (scale === 6) {
    tibP = width === 'xs' ? '36px' : '44px';
    tibS = width === 'xs' ? '26px' : '34px';
    // tibP = (width === 'xs') ? '55px' : '66px';
    // tibS = (width === 'xs') ? '40px' : '48px';

    wesP = width === 'xs' ? '43px' : '52px';
    wesS = width === 'xs' ? '31px' : '37px';

    gap = width === 'xs' ? '110px' : '132px';
  } else if (scale === 7) {
    tibP = width === 'xs' ? '36px' : '44px';
    tibS = width === 'xs' ? '26px' : '34px';
    // tibP = (width === 'xs') ? '66px' : '79px';
    // tibS = (width === 'xs') ? '48px' : '57px';

    wesP = width === 'xs' ? '52px' : '62px';
    wesS = width === 'xs' ? '37px' : '45px';

    gap = width === 'xs' ? '132px' : '158px';
  } else if (scale === 8) {
    tibP = width === 'xs' ? '36px' : '44px';
    tibS = width === 'xs' ? '26px' : '34px';
    // tibP = (width === 'xs') ? '79px' : '95px';
    // tibS = (width === 'xs') ? '57px' : '68px';

    wesP = width === 'xs' ? '62px' : '74px';
    wesS = width === 'xs' ? '45px' : '54px';

    gap = width === 'xs' ? '158px' : '190px';
  }

  const styleHtmlVar = {
    Tib: `p.Tib {font-family: 'Microsoft Himalaya'; font-size: ${tibP}} 
                p.Tib small {font-size: ${tibS}} 
                h6.Tib {font-family: 'Microsoft Himalaya'; font-size: ${tibP};  margin: ${tibS}} `,
    Dra: `p.Dra {font-family: 'Times New Roman'; font-size: ${wesP}} 
                p.Dra small {font-size: ${wesS}} 
                h6.Dra {font-family: 'Times New Roman'; font-size: ${wesP}; margin: ${wesP}}`,
    Eng: `p.Eng {font-family: 'Times New Roman'; font-size: ${wesP}} 
                p.Eng small {font-size: ${wesS}} 
                h6.Eng {font-family: 'Times New Roman'; font-size: ${wesP}; margin: ${wesP}} `,
    // Rus:    `p.Rus {font-family: 'Times New Roman'; font-size: ${wesP}}
    //         p.Rus small {font-size: ${wesS}}
    //         h6.Rus {font-family: 'Times New Roman'; font-size: ${wesP}; margin: ${wesP}} `,
  };

  const checkedVariants = {
    Tib: true,
    Dra: true,
    Eng: true,
    // 'Rus': true,
  };

  var lyricsHtml = `<style type='text/css'> p { margin: 0.5rem 0; line-height: 150%; -webkit-text-size-adjust: 100%; ${
    scale === 1 ? `white-space: pre-wrap; overflow-wrap: break-word;` : ``
  }} h6 {text-align:center; overflow-wrap: break-word;} .songLine {margin-bottom: ${gap}}`;
  //var lyricsHtml = `<style type='text/css'> p { -webkit-text-size-adjust: 100%; ${scale === 1 ? `white-space: pre-wrap; overflow-wrap: normal;` : ``}} h6 {text-align:center}`;

  for (var keyVar in checkedVariants) {
    if (!checked[keyVar] || lyrics['lyrics' + keyVar] === undefined) {
      delete checkedVariants[keyVar];
    } else {
      lyricsHtml = lyricsHtml + styleHtmlVar[keyVar];
    }
  }
  lyricsHtml = lyricsHtml + '</style>';

  for (keyVar in checkedVariants) {
    //only once, as a base for a cycle
    for (let keyLine in lyrics['lyrics' + keyVar]) {
      let songLine = false;
      //add a line
      let line1 = lyrics['lyrics' + keyVar][keyLine];
      //if it's a line with flowers put an image
      if (line1 === '%1f' || line1 === `<span>%1f</span>`) {
        lyricsHtml =
          lyricsHtml +
          `<p style="text-align:center;"> <img src="/flowers1.png" width="50%" style="max-width: 300px"/> </p>`;
      } else if (line1 === '%2f' || line1 === `<span>%2f</span>`) {
        lyricsHtml =
          lyricsHtml +
          `<p style="text-align:center;"> <img src="/flowers2.png" width="25%" style="max-width: 150px"/> </p>`;
      } else {
        if (keyLine === '1' || line1.search('<h6>') > -1) {
          //title: if <h6> or the first line
          //remove all the first &nbsp
          while (line1.search('&nbsp;') === 0) {
            line1 = line1.replace('&nbsp;', '').trim();
          }
          if (line1.search('<h6>') === -1) {
            //first line
            line1 = `<h6 class=${keyVar}>${line1}</h6>`;
          } else {
            //first or others
            line1 = line1.replace('<h6>', `<h6 class=${keyVar}>`);
          }
          line1 = changeNbspToSpace(line1, keyVar);
          lyricsHtml = lyricsHtml + line1;
          //console.log('lyricshtml', lyricsHtml);
        } else if (!(line1 === '&nbsp;' || line1 === '')) {
          //if it's not empty

          //if width === xs remove all the first &nbsp and whitespaces
          if (width === 'xs') {
            line1 = line1.trim();
            while (line1.search('&nbsp;') === 0) {
              line1 = line1.replace('&nbsp;', '').trim();
            }
          }
          line1 = changeNbspToSpace(line1, keyVar);
          //paragraf of line of the song
          songLine = true;
          lyricsHtml = lyricsHtml + "<div class='songLine'>";
          lyricsHtml =
            lyricsHtml + "<p class='" + keyVar + "'>" + line1 + '</p>';
        }

        //add lines of another checked variants
        for (let keyVar2 in checkedVariants) {
          //if it's the same variant we don't add the line
          if (keyVar2 !== keyVar) {
            let line2 = lyrics['lyrics' + keyVar2][keyLine];

            if (line2 === undefined) {
              //do nothing
            } else {
              //title: if <h6> or the first line
              if (keyLine === '1' || line2.search('<h6>') > -1) {
                //remove all the first &nbsp
                while (line2.search('&nbsp;') === 0) {
                  line2 = line2.replace('&nbsp;', '').trim();
                }
                if (line2.search('<h6>') === -1) {
                  //first line
                  line2 = `<h6 class=${keyVar2}>${line2}</h6>`;
                } else {
                  //first or others
                  line2 = line2.replace('<h6>', `<h6 class=${keyVar2}>`);
                }
                line2 = changeNbspToSpace(line2, keyVar2);
                lyricsHtml = lyricsHtml + line2;
              } else if (!(line2 === '&nbsp;' || line2 === '')) {
                //if width === xs remove all the first &nbsp and whitespaces
                if (width === 'xs') {
                  line2 = line2.trim();
                  while (line2.search('&nbsp;') === 0) {
                    line2 = line2.replace('&nbsp;', '').trim();
                  }
                }
                line2 = changeNbspToSpace(line2, keyVar2);
                lyricsHtml =
                  lyricsHtml + "<p class='" + keyVar2 + "'>" + line2 + '</p>';
              }
            }
          }
        }
        if (songLine) {
          lyricsHtml = lyricsHtml + '</div>';
        }
      }
    }
    break;
  }
  lyricsHtml = lyricsHtml + `<p></p>`;
  return lyricsHtml;
}

function FullScreenLyricsDialog(props) {
  const classes = useStyles();
  const [scale, setScale] = React.useState(1);

  const html = `<div style="max-width: fit-content; max-width: max-content; margin:0 auto; "> ${createLyricsHtml(
    props.lyrics,
    props.checked,
    props.width,
    scale
  )} </div>`;

  const zoomIt = (zoominout) => {
    if (zoominout === 1) {
      setScale(scale === 8 ? scale : scale + 1);
    } else {
      setScale(scale === 1 ? scale : scale - 1);
    }
  };

  return (
    <Dialog
      fullScreen
      open={props.open}
      onClose={props.handleClose}
      TransitionComponent={Transition}
    >
      <AppBar className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <Typography variant='h6' className={classes.title}>
            {props.title}
          </Typography>
          <IconButton
            color='inherit'
            onClick={() => zoomIt(1)}
            aria-label='zoom in'
            size='small'
            disabled={scale === 8 ? true : false}
          >
            <ZoomInIcon />
          </IconButton>
          <IconButton
            color='inherit'
            onClick={() => zoomIt(-1)}
            aria-label='zoom out'
            size='small'
            disabled={scale === 1 ? true : false}
          >
            <ZoomOutIcon />
          </IconButton>
          <IconButton
            color='inherit'
            onClick={props.handleClose}
            aria-label='close'
            size='medium'
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <Container className={classes.fullScreenHtmlContainer}>
          {ReactHtmlParser(html)}
        </Container>
      </DialogContent>
    </Dialog>
  );
}

function FullScreenLyrics(props) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        startIcon={<FullscreenIcon />}
        onClick={handleClickOpen}
        variant='outlined'
      >
        Full screen lyrics
      </Button>
      <FullScreenLyricsDialog
        handleClose={handleClose}
        open={open}
        lyrics={props.lyrics}
        checked={props.checked}
        width={props.width}
        title={props.title}
      />
    </div>
  );
}

function Lyrics(props) {
  const [checked, setChecked] = useState({
    Tib: true,
    Dra: true,
    Eng: true,
    Rus: true,
  });

  const myRef = useRef(null);

  const handleChange = (name) => (event) => {
    setChecked({ ...checked, [name]: event.target.checked });
  };

  const { pathname } = useLocation();

  useEffect(() => {
    myRef.current.scrollTop = 0;
  }, [pathname]);

  const showWord = (event) => {
    event.stopPropagation();
    console.log('show word');
  };

  const lyrics = props.lyrics === undefined ? {} : props.lyrics;
  //const html = createLyricsHtml(lyrics);
  const classes = useStyles();
  const { width } = props;

  return (
    <Container className={classes.container}>
      <Paper
        className={
          width === 'xs' ? classes.lyricsPaperXS : classes.lyricsPaperScroll
        }
        elevation={width === 'xs' ? 0 : 0}
        ref={myRef}
      >
        <Toolbar className={classes.toolbarLyrics}>
          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checked.Tib}
                  onChange={handleChange('Tib')}
                  value='checkedTib'
                />
              }
              label='བོད་ཡིག'
              disabled={lyrics['lyricsTib'] === undefined ? true : false}
            />
            <span>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked.Dra}
                    onChange={handleChange('Dra')}
                    value='checkedDra'
                  />
                }
                label='Drajyor'
                disabled={lyrics['lyricsDra'] === undefined ? true : false}
              />
              <Glossary on='Drajyor' />
            </span>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checked.Eng}
                  onChange={handleChange('Eng')}
                  value='checkedEng'
                />
              }
              label='English'
              disabled={lyrics['lyricsEng'] === undefined ? true : false}
            />
            {/* <FormControlLabel
              control={
                <Checkbox
                  checked={checked.Rus}
                  onChange={handleChange('Rus')}
                  value='checkedRus'
                />
              }
              label='Русский'
              disabled={lyrics['lyricsRus'] === undefined ? true : false}
            /> */}
          </FormGroup>
          <div className={classes.grow} />
          {props.dontShowFullScreen !== true && (
            <FullScreenLyrics
              lyrics={lyrics}
              checked={checked}
              width={width}
              title={props.title}
            />
          )}
        </Toolbar>
        <Container className={classes.lyricsText}>
          {ReactHtmlParser(createLyricsHtml(lyrics, checked, width, 1))}
        </Container>
      </Paper>
    </Container>
  );
}

Lyrics.propTypes = {
  width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
};

export default withWidth()(Lyrics);
