import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import { accentColor } from '../Utils/constants';
import { getLocalAutoplay, setLocalAutoplay } from '../KhaitaPlayer/AutoplayLocal';

const CustomSwitch = withStyles({
    switchBase: {
        //color: accentColor,
        '&$checked': {
            color: accentColor,
        },
        '&$checked + $track': {
            backgroundColor: accentColor,
        },
    },
    checked: {},
    track: {},
})(Switch);

export default function AutoplaySwitch(props) {

    /*//Autoplay
    const [state, setState] = useState({
        autoplaySwitcher: getLocalAutoplay()
    });

    useEffect(() => {
        setState({ ...state, autoplaySwitcher: getLocalAutoplay()});
    }, []);

    const handleChange = (event) => {
        //setState({ ...state, [event.target.name]: event.target.checked });
        setLocalAutoplay(event.target.checked);
    };*/

    return (
        <FormControlLabel
            control =
                {<CustomSwitch 
                    checked={props.state.autoplaySwitcher} 
                    onChange={props.handleChange} 
                    name='autoplaySwitcher'
                    onClick={(event) => {event.stopPropagation()}}
                />}
            labelPlacement='start'
        />
    )
}


/*
export default function AutoplaySwitch() {
    const [state, setState] = useState({autoplaySwitcher: getLocalAutoplay()});

    useEffect(() => {
        setState({ ...state, autoplaySwitcher: getLocalAutoplay()});
    }, []);
  
    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
        setLocalAutoplay(event.target.checked);
    };

    return (
        <FormControlLabel
            control =
                {<CustomSwitch 
                    checked={state.autoplaySwitcher} 
                    //checked={autoplay} 
                    onChange={handleChange} 
                    name='autoplaySwitcher'
                    //name='autoplay'
                    onClick={(event) => {event.stopPropagation()}}
                />}
            //label='Autoplay'
            labelPlacement='start'
        />
    )
}


*/